import React from 'react';

import Icon from './Icon';

export default function StrokeCheckboxOn24({
  className,
  text = '',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12ZM17.0336 9.25593L10.9225 15.367C10.5971 15.6925 10.0694 15.6925 9.74401 15.367L6.96623 12.5893C6.6408 12.2638 6.6408 11.7362 6.96623 11.4108C7.29167 11.0853 7.81931 11.0853 8.14474 11.4108L10.3333 13.5993L15.8551 8.07742C16.1806 7.75198 16.7082 7.75198 17.0336 8.07742C17.3591 8.40286 17.3591 8.93049 17.0336 9.25593Z"
          fill="#376EF2"
        />
      </svg>
    </Icon>
  );
}
